.vuiTitle {
  color: $colorText;
}

$size: (
  xxs: $sizeS,
  xs: $sizeM,
  s: $sizeM * 1.125,
  m: $sizeM * 1.25,
  l: $sizeL,
  xl: $sizeXl,
  xxl: $sizeXxl,
);

@each $sizeName, $sizeValue in $size {
  .vuiTitle--#{$sizeName} {
    font-size: $sizeValue;
  }
}

$align: left, center, right;

@each $alignValue in $align {
  .vuiTitle--#{$alignValue} {
    text-align: #{$alignValue};
  }
}
