.vuiFlexItem {
  display: flex;
  flex-direction: column;

  @for $i from 0 through 10 {
    &.vuiFlexItem--flexGrow#{$i} {
      flex-grow: $i;
    }
  }
}
