// Semantic colors
$colorAccent: #7027f6 !default;
$colorPrimary: rgb(38, 76, 214) !default;
$colorSuccess: #04821f !default;
$colorWarning: #f8e00b !default;
$colorDanger: #c41535 !default;

// Semantic shades
$colorAccentLightShade: #eadfff !default;
$colorDangerLightShade: #ffd7d7 !default;

// Neutral colors
$colorEmptyShade: #ffffff !default;
$colorLightShade: #f3f7fb !default;
$colorMediumShade: #cbcdde !default;
$colorDarkShade: #69707d !default;
$colorDarkerShade: #2c313a !default;
$colorFullShade: #000 !default;
